import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesFAQ: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Frequently Asked Questions</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_faq.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Frequently Asked Questions</h1>
          <h2>
            Struggling with AFK Journey? We got you covered! Check the
            community's most common questions!
          </h2>
          <p>
            Last updated: <strong>02/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="What type of game is AFK Journey?" />
        <p>
          AFK Journey is a{' '}
          <strong>top-down open world auto-chess style gacha game</strong> with
          a combat system revolving around activatable Ultimates and
          automatically activated skills. The game focuses heavily on
          teambuilding, team variety, and utilizing interesting character
          combinations to take on the content ahead of you. The game is
          noteworthy for its colorful cast of characters and unique artstyle,
          something that’s been imported from its sister game:{' '}
          <strong>
            AFK Arena, but this time in a 3D setting instead of 2D sprites.
          </strong>
        </p>
        <SectionHeader title="Does AFK Journey have a PC client?" />
        <p>
          <strong>Yes!</strong> You can download the PC client from the official
          AFK Journey website{' '}
          <a
            href="https://afkjourney.farlightgames.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Is AFK Journey a main or side type of game?" />
        <p>
          <strong>Definitely it's a side game - like most idle games</strong>.
          While the initial few days will give you plenty of things to do -
          especially considering the fact that the game has no stamina system -
          you will hit a wall sooner or later and the only way to push through
          it will be to wait for resources to pile up, so you can upgrade your
          characters.
        </p>
        <SectionHeader title="How long does it take to finish the dailies?" />
        <p>
          Once you finish the story and the map content, your daily adventure
          with AFK Journey will take you somewhere between{' '}
          <strong>5 and 20</strong> minutes, depending on what exactly do you
          need to do.
        </p>
        <ul>
          <li>
            Doing dailies themselves takes like 2 minutes. You just click around
            the menus and gather the rewards from daily quests,
          </li>
          <li>
            Then the Arena takes like 5 minutes as you need to watch the battles
            (there's no skip yet),
          </li>
          <li>
            As for Dream Realm, Legend Trial and Battle Drills. They take more
            time, but exactly how much depends on how hard you want to push in
            them. If you just want to do your daily attacks, it will take a few
            minutes at most.
          </li>
        </ul>
        <SectionHeader title="Is the game free to play friendly or pay to win?" />
        <p>
          Definitely <strong>free to play friendly</strong>. We have played the
          game for over over half a year on the PTR (Public Test Realm) and in
          that time we were able to obtain all characters and ascend most of
          them to the highest tier. As a F2P player you can even compete in both
          Arena and Dream Realm (boss killing mode) as once the 'ceiling' of
          ascension is reached, your tactics matter more.
        </p>
        <p>Check Zeeebo's video if you want to learn more about this topic:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="_Y2H7nhha-c" />
          </Col>
        </Row>
        <SectionHeader title="How do the servers work?" />
        <p>
          AFK Journey uses the common idle game server system where once it
          reaches certain number of players, a new server is automatically
          opened and when you create your account for the first time, you've
          thrown at the most recent server.
        </p>
        <p>
          <strong>Servers also aren't split into regions</strong> so you don't
          have to worry that your friend from another country won't be able to
          play with you. Still, what is very important is that currently{' '}
          <strong className="red">guilds are tied to the server</strong> and if
          your friend plays on another one, you won't be able to join the same
          guild. We're really hoping that this will get changed soon after the
          release - just like how it works in AFK Arena.
        </p>
        <SectionHeader title="Is it worth to reroll?" />
        <p>
          That's the most important question as normally in idle games,
          rerolling isn't worth because those types of games heavily rely on
          dupes, and{' '}
          <strong>
            AFK Journey isn't an exception unless you want to heavily min-max
            who you will start with
          </strong>
          . Here's why:
        </p>
        <ul>
          <li>
            The first 2 pities you reach on the normal banner are 'fixed' when
            it comes to the <AFKItem name="S-Level" /> characters you can
            obtain:
          </li>
          <ul>
            <li>
              The <strong>first pity</strong> (10 pulls) guarantees you a decent
              damage dealer for Story progression in the game{' '}
              <AFKCharacter mode="inline" slug="cecia" enablePopover /> who also
              can be used with success in other modes, but she will drop off
              there in late game (due to being too slow),
            </li>
            <li>
              The <strong>second pity</strong> (20 pulls) guarantees you one of
              the 3 strongest Healers / Supports (equal chance to get each of
              them): <AFKCharacter mode="inline" slug="rowan" enablePopover />{' '}
              <AFKCharacter mode="inline" slug="hewynn" enablePopover />{' '}
              <AFKCharacter
                mode="inline"
                slug="smokey-and-meerky"
                enablePopover
              />
            </li>
          </ul>
        </ul>
        <p>
          Still, if you want to learn more about rerolling, check our dedicated
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll guide"
            link="/afk-journey/guides/reroll"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_reroll.jpg"
                alt="Introduction to the game"
              />
            }
          />
        </Row>
        <SectionHeader title="What are the rates?" />
        <p>
          This depends on the banner as there are multiple types of them. The
          rates for <AFKItem name="S-Level" /> characters start from{' '}
          <strong>2.05% and end at 5.22%</strong>. Still, compared to AFK Arena,
          the <AFKItem name="A-level" /> characters aren't fodder and a lot of
          them sit among the best characters in the whole game.
        </p>
        <p>You can learn more about the banners in our dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Banners and rates"
            link="/afk-journey/guides/banners-and-rates"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_gacha.jpg"
                alt="Banners and rates"
              />
            }
          />
        </Row>
        <SectionHeader title="How important is Wishlist and who should I put there?" />
        <p>
          The wishlist{' '}
          <strong>
            unlocks after doing 30 pulls when it comes to the All-Hero
            Recruitment banner
          </strong>{' '}
          and is available from the start for the Epic Recruitment banner.{' '}
          <strong>
            When you fully fill the wishlist, any character you pull will come
            from the Wishlist
          </strong>
          . Which makes it really important to set your Wishlist right.
        </p>
        <p>
          When it comes to <AFKItem name="S-Level" /> characters, ascending them
          is very important and requires both dupes and Acorns. So making a
          mistake in focusing your resources and 'wishlist' spot into someone
          who doesn't deserve it will kick it in your ass soon, slowing down
          your progression. As for <AFKItem name="A-Level" /> characters, you
          require a lot more dupes (64 compared to 8 for S-Level) to raise an
          A-Level character to Supreme+, so again you have to wisely choose who
          you want to place on your wishlist.
        </p>
        <p>
          To learn who you should place on the Wishlist, check our dedicated
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Wishlist tips"
            link="/afk-journey/guides/wishlist-tips"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_wishlist.jpg"
                alt="Wishlist tips"
              />
            }
          />
        </Row>
        <SectionHeader title="How should I setup my team and who should I use?" />
        <p>
          Probably this is the most common question we encounter when it comes
          to new players. AFK Journey is a{' '}
          <strong>very team-building heavy and focused game</strong> with
          various synergies between characters that sometimes drastically impact
          how they perform. That's why we have created a dedicated guide that
          goes over core teams for each of the four common factions available in
          the game, so you can progress no matter who you pull or want to play
          with. As for the characters, please do check our tier list to find out
          how they perform in various content.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building (beginner)"
            link="/afk-journey/guides/team-building"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_team.jpg"
                alt="Team building"
              />
            }
          />
          <CategoryCard
            title="Tier List"
            link="/afk-journey/tier-list"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_tier.jpg"
                alt="Tier List"
              />
            }
          />
        </Row>
        <SectionHeader title="What should I buy from the in-game shop?" />
        <p>
          There's a lot of different shops available in AFK Journey and you can
          even obtain <AFKItem name="A-Level" /> and <AFKItem name="S-Level" />{' '}
          characters so making the right choice who and what you should
          prioritize is very important. We have went over each of the shops and
          what you should focus on in a dedicated guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Shop (Emporium) guide"
            link="/afk-journey/guides/shop-guide"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_shops.jpg"
                alt="Shop (Emporium) guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Which Celestial or Hypogean should I buy from the shop?" />
        <p>
          <AFKCharacter mode="inline" slug="reinier" enablePopover /> without a
          doubt should be your first <AFKItem name="Celestial" /> /{' '}
          <AFKItem name="Hypogean" /> character you will buy from the Guild
          Store. Not only he's the most universal character as he's a support
          who can disrupt enemy formation and has massive damage taken debuff
          that makes him perfect against bosses, but he also already works great
          at just a single copy - unlike the three other C/H characters.
        </p>
        <SectionHeader title="What should I use my Diamonds on?" />
        <p>
          Majority of them you will use on pulling, but you have to keep some
          for the discounted items in the Stores.
        </p>
        <SectionHeader title="I want to spend! What are the best packs to buy?" />
        <p>Check our dedicated guide for low and high spenders:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Spending guide"
            link="/afk-journey/guides/spending-guide"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_spending.jpg"
                alt="Spending guide"
              />
            }
          />
        </Row>
        <SectionHeader title="What's the endgame?" />
        <p>
          Once you clear the currently available quest line and reveal the whole
          map, you will have to wait for future updates to learn how the story
          will continue. But there has to be something to do so players don't
          get bored and AFK Journey does it well. The currently available
          endgame revolves around 4 modes:
        </p>
        <ul>
          <li>
            <strong>Arena</strong> - obviously PVP is a form of endgame where
            you can climb up the ranks to be the best and to obtain better
            rewards,
          </li>
          <li>
            <strong>Dream Realm</strong> - the boss killing mode that has its
            own leaderboards and whoever deals more damage to the boss, gets
            better rewards. There are currently four Dream Realm bosses who
            rotate daily and each boss requires you to build your team in a
            different way to counter its abilities,
          </li>
          <li>
            <strong>Battle Drill</strong> - this is the endgame mode for guilds.
            You compete with every other guild on your server (for now) on a
            special map where you have to progress to the final boss by
            destroying strongholds and roaming enemies. This mode requires great
            coordination to perform well.
          </li>
          <li>
            <strong>Trial of Abyss</strong> - unlocks once you finish the
            initial story campaign and clear all 1125 AFK Stages, but also the
            server needs to be at least 18 days old. The mode is basically a
            dungeon you have to climb down and beat a team guarding each floor.
            Here you will encounter teams featuring multiple{' '}
            <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
            characters who will test you.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesFAQ;

export const Head: React.FC = () => (
  <Seo
    title="Frequently Asked Questions | AFK Journey | Prydwen Institute"
    description="Struggling with AFK Journey? We got you covered! Check the community's most common questions!"
    game="afk"
  />
);
